// --------------------------------
// Slide resource
// Note: Please use `em` rather than `rem` within slide contents so it renders properly when scaled.
// Numerous !important tags are used in order to battle w/ .ck-editable due to the change in css reset
// --------------------------------

.slide__container {
  @apply border-base bg-focus border;
  margin-bottom: 1rem;
  min-height: 540px;
  position: relative;
  width: 100%;
  padding: 1.125% 2% 1.125% 2%;
  border-bottom-width: 3px;
  border-radius: $border-radius;
  aspect-ratio: 16 / 9;
}

.slide {
  @apply grid h-full w-full;
  font-size: 14px; // maintains font aspect from 4:3 constructed slides
  line-height: 1.4;
  grid-template-rows: repeat(8, 1fr);
  grid-template-columns: repeat(12, 1fr);

  td,
  th {
    .slide__text {
      height: 100%;
      min-width: 20px;
      min-height: 1.75em;

      p,
      ul,
      ol,
      li {
        margin: 0 !important;
      }
    }
  }

  th,
  .th {
    @apply bg-neutral-100 text-important;

    p {
      @apply text-important;
      font-weight: $font-weight--bold;
    }
  }

  p,
  li {
    // For some reason, these font-size rules make text disappear in print in
    // some situations
    font-size: $fs-xl-em;
    line-height: 1.4;
  }

  li>p {
    font-size: 1em;
  }

  ul,
  ol {
    font-size: 1em;

    ul,
    ol {
      font-size: calc(1em / 1.53);
    }

    padding-left: 1.8em !important;
  }

  li[style*='center'] {
    list-style-position: inside;
  }

  // typography
  .fs-xsmall,
  .slide__caption>p,
  .slide__caption>ol>li,
  .slide__caption>ul>li {
    line-height: 1.6em;
    font-size: 0.875em;
  }

  .slide__caption .fs-xsmall {
    font-size: calc(0.875em * 1.142857);
  }

  .fs-small {
    font-size: calc(1em / 1.25);
  }

  .slide__caption .fs-small {
    // calculate from 11.2px to ~24.48px
    font-size: calc(0.875em * 1.6);
  }

  .fs-normal {
    font-size: 1em;
  }

  .fs-large {
    font-size: calc(1.53em / 1.25);
  }

  .fs-xlarge {
    font-size: calc(1.953em / 1.25);

    @include breakpoint(medium) {
      font-size: calc(2em / 1.25);
    }
  }

  .fs-xxlarge {
    font-size: calc(2em / 1.25);

    @include breakpoint(medium) {
      font-size: calc(2.4em / 1.25);
    }
  }

  .fs-xxxlarge {
    font-size: calc(2.4em / 1.25);
    font-weight: bolder;

    @include breakpoint(medium) {
      font-size: calc(3.052em / 1.25);
    }
  }
}

.slide__content {
  @include flexbox();
  @include flex-val(1);
  padding: 1rem;
  overflow: hidden;

  &.slide__content--vtop {
    @include flexalign(flex-start);

    .slide__image {

      img,
      svg {
        top: 0;
      }

      svg {
        height: auto;
        width: 100%;
      }
    }
  }

  &.slide__content--vcenter {
    >*:not(.drag-overlay) {
      margin-top: auto !important;
      margin-bottom: auto !important;
    }

    .slide__image {
      @apply inline-block;
    }

    .slide__image {

      img,
      svg {
        @apply bottom-0 top-0 mb-auto mt-auto;
      }
    }
  }

  &.slide__content--vbottom {
    @include flexalign(flex-end);

    .slide__image {

      img,
      svg {
        bottom: 0;
      }

      svg {
        height: auto;
        width: 100%;
      }
    }
  }

  &.slide__content--hleft {
    @include flexjustify(flex-start);

    .slide__image {
      text-align: left;

      img,
      svg {
        left: 0;
      }

      svg {
        height: 100%;
        width: auto;
      }
    }
  }

  &.slide__content--hcenter {
    >*:not(.drag-overlay) {
      margin-left: auto !important;
      margin-right: auto !important;
    }

    .slide__image {
      @include flexbox();
      @include flexjustify(center);
      text-align: center;
    }
  }

  &.slide__content--hright {
    @include flexjustify(flex-end);

    .slide__image {
      text-align: right;

      img,
      svg {
        right: 0;
      }

      svg {
        height: 100%;
        width: auto;
      }
    }
  }

  &.slide__content--flush-vertical {
    padding-top: 0;
    padding-bottom: 0;
  }

  .slide__image {
    display: flex;
    height: 100%;
    justify-content: center;
    width: 100%;
    position: relative;

    img,
    svg {
      object-fit: contain; // allows us to keep aspect ratio without using JS
      height: 100%;
      width: auto;
      max-width: 100%;
    }

    img {
      position: absolute; // required to handle alignment of large images where object-fit creates extra white space
      display: block;
    }

    >svg {
      width: auto;
      max-width: 100%;
      height: 100%;
      position: relative;
    }
  }
}

// tables
.slide--container__table {
  @include flexbox();
  @include flexalign(center);
  @include flexdirection(column);
  width: 100%;
  z-index: 101;
}

.slide__content {
  table {

    td,
    th {
      padding: 0.5em !important;
    }

    &.responsive {
      // Note: Using 'large' instead of 'large up' so style will be applied
      // to both screen and print
      // See issue https://github.com/zurb/foundation-sites/issues/10976
      display: table;
      width: 100%;

      .fs-small,
      .fs-normal,
      .fs-large,
      .fs-xlarge,
      .fs-xxlarge,
      .fs-xxxlarge {
        display: inline-block;
      }
    }

    tbody th:first-child,
    tbody th:last-child {
      background-image: none;
    }
  }
}

// slide typography
.font-elementary .slide__text * {
  letter-spacing: 0.03em;
}

.slide__text {
  width: 100%;
  @include ol-list-style-parens;

  ol,
  ul,
  p {
    margin-top: 1em !important;
    margin-bottom: 0 !important;

    &:first-child {
      margin-top: 0 !important;
    }

    >ul,
    >ol {
      margin-top: -1em !important;
    }
  }

  li {
    margin-bottom: 0.66em !important;

    >p {
      margin-top: 0 !important;
    }
  }

  &.slide__text--callout {
    @apply rounded-xl bg-blue-50 p-4;

    &.slide__text--callout p {
      @apply text-blue-500;
    }

    &.slide__text--callout ol li {
      @apply text-blue-500;
    }

    &.slide__text--callout ul li {
      @apply text-blue-500;
    }
  }

  &.slide__text--excerpt {
    @apply bg-neutral-200 rounded-xl p-4;

    &.slide__text--excerpt p {
      @apply text-primary-500;
    }

    &.slide__text--excerpt ol li {
      @apply text-primary-500;
    }

    &.slide__text--excerpt ul li {
      @apply text-primary-500;
    }
  }

  &.slide__text--note {
    @apply rounded-xl bg-green-100 p-4;

    &.slide__text--note p {
      @apply text-green-500;
    }

    &.slide__text--note ol li {
      @apply text-green-500;
    }

    &.slide__text--note ul li {
      @apply text-green-500;
    }
  }

  .math-tex {
    @apply inline-block;
  }

  .slide__dialogue {
    @include ol-list-style-parens;
    margin-bottom: -1rem;

    p:last-child {
      margin-bottom: 0 !important; // override scaling
    }
  }

  /* Safari 9 and 10.0 */
  /* Graceful fallback for grid */
  @supports not (display: grid) {
    .slide {
      overflow: scroll;

      .slide__image {
        position: static;

        img,
        svg {
          position: relative;
        }
      }

      .slide__content.slide__content--vcenter,
      .slide__content.slide__content--hcenter {

        .slide__image img,
        svg {
          position: relative;
          transform: none;
          left: auto;
          top: auto;
        }
      }
    }
  }

  /* Safari 10.1+ */
  /* Hack to fix issue in Safari not resolving floats correctly at certain resolutions */
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) {
      #preview-toggle {
        margin-bottom: 0.375rem;

        .toggle-btn__inline {
          label>div {
            display: inline;

            >.icon {
              display: inline;
              float: none;
            }
          }
        }
      }
    }
  }
}

@supports (hanging-punctuation: first) and (font: -apple-system-body) and (-webkit-appearance: none) {
  .slide__content .slide__image img {
    position: relative;
    height: 100%;
  }
}
